import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AccidentResponse, Photo, SectionType, AccidentPhotoType, isDetail, isCounterParty } from './enums';
import { translations } from './translations';

const blobs: Blob[] = [];

try {
  const urlParams = new URLSearchParams(window.location.search);
  const hash = urlParams.get('id');

  fetch(`${process.env.API_URL}/v1/accidents/${hash}`)
    .then(res => res.json())
    .then((res: AccidentResponse) => {
      document.querySelector('[data-type="placeholder"]')?.classList.add('hidden');
      const view = document.querySelector('[data-type="view"]')!;
      view.classList.remove('hidden');

      const elDate = document.querySelector('[data-type="date"]')! as HTMLHeadingElement;
      elDate.innerText = new Date(res.date)
        .toLocaleDateString('cs-CZ', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
        .replace(' v ', ', ');

      const elAddress = document.querySelector('[data-type="address"]')! as HTMLHeadingElement;
      elAddress.innerText =
        [res.street, res.city, res.zip, res.country].filter(Boolean).join(', ') ||
        [res.latitude, res.longitude].join(', ');

      const sections: { [index: string]: { main: Photo[]; detail: Photo[] } } = {
        [SectionType.MyVehicle]: {
          main: [],
          detail: [],
        },
        [SectionType.CounterpartyVehicle]: {
          main: [],
          detail: [],
        },
        [AccidentPhotoType.property]: {
          main: [],
          detail: [],
        },
        [AccidentPhotoType.site]: {
          main: [],
          detail: [],
        },
        [AccidentPhotoType.euProtocol]: {
          main: [],
          detail: [],
        },
        [AccidentPhotoType.policeProtocol]: {
          main: [],
          detail: [],
        },
        [SectionType.Other]: {
          main: [],
          detail: [],
        },
      };

      res.photos.forEach(photo => {
        if (photo.category === AccidentPhotoType.otherFallback) {
          photo.category = AccidentPhotoType.other;
        }

        if (
          [
            AccidentPhotoType.other,
            AccidentPhotoType.site,
            AccidentPhotoType.property,
            AccidentPhotoType.policeProtocol,
            AccidentPhotoType.euProtocol,
          ].includes(photo.category)
        ) {
          sections[photo.category][isDetail(photo.category) ? 'detail' : 'main'].push(photo);
        } else if (isCounterParty(photo.category)) {
          sections[SectionType.CounterpartyVehicle][isDetail(photo.category) ? 'detail' : 'main'].push(photo);
        } else {
          sections[SectionType.MyVehicle][isDetail(photo.category) ? 'detail' : 'main'].push(photo);
        }
      });

      const elContent = view.querySelector('.content')!;
      Object.keys(sections).forEach(key => {
        const _s = sections[key];
        if (_s.main.length === 0 && _s.detail.length === 0) {
          return;
        }

        const elSection = document.createElement('section');
        const elSectionTitle = document.createElement('h2');
        elSectionTitle.innerText = translations[key] || key;
        elSection.appendChild(elSectionTitle);
        [_s.main, _s.detail].forEach((album, i) => {
          let elUl = document.createElement('ul');
          if (album.length === 0) {
            return;
          }

          album.forEach(photo => {
            const elLi = document.createElement('li');
            const elA = document.createElement('a');
            elA.href = photo.url;
            elA.target = '_blank';
            const elImg = document.createElement('img');
            elImg.src =
              'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8/+VLPQAJCQNYcRtFDwAAAABJRU5ErkJggg==';
            elA.appendChild(elImg);
            fetch(photo.url)
              .then(res => res.blob())
              .then(blob => {
                const url = URL.createObjectURL(blob);
                blobs.push(blob);
                elImg.src = url;
                elImg.alt = photo.tag || translations[key] || key;
              });

            const elP = document.createElement('p');
            elP.innerText = (() => {
              if ([SectionType.MyVehicle, SectionType.CounterpartyVehicle].includes(key as SectionType)) {
                return translations[photo.category] || photo.category;
              }

              return 'Detail škody';
            })();
            elA.appendChild(elP);
            elLi.appendChild(elA);
            elUl.appendChild(elLi);
          });

          if (elUl.children.length > 0) {
            if (i === 1 && _s.main.length > 0) {
              const elHr = document.createElement('hr');
              const elH2 = document.createElement('h2');
              elH2.innerText = 'Detail poškození';
              elSection.appendChild(elHr);
              elSection.appendChild(elH2);
            }

            elSection.appendChild(elUl);
          }
        });

        elContent.appendChild(elSection);
      });
    })
    .catch(e => {
      console.warn(e);
      alert('Záznam o nehodě neexistuje.');
    });
} catch (e) {
  console.warn(e);
}

document.querySelector('[data-type="download"]')?.addEventListener('click', e => {
  e.preventDefault();

  document.body.classList.add('loading');
  const zip = new JSZip();
  const folder = zip.folder('obrazky');

  blobs.forEach((blob, i) => {
    folder?.file(`${i + 1}.jpg`, blob, { base64: true });
  });

  zip.generateAsync({ type: 'blob' }).then(function (content) {
    saveAs(content, 'prilohy.zip');
    document.body.classList.remove('loading');
  });
});
