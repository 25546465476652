export type Photo = {
  category: AccidentPhotoType;
  id: number;
  tag?: string;
  url: string;
};

export type AccidentResponse = {
  city?: string;
  country?: string;
  date: string;
  id: number;
  latitude: number;
  longitude: number;
  photos: Photo[];
  street?: string;
  zip?: string;
};

export enum SectionType {
  MyVehicle = 'myVehicle',
  CounterpartyVehicle = 'counterpartyVehicle',
  Property = 'property',
  Site = 'site',
  PoliceProtocol = 'police_protocol',
  Other = 'other',
}

export enum AccidentPhotoType {
  policeProtocol = 'policeProtocol',
  euProtocol = 'euProtocol',
  regPlate = 'regPlate',
  frontLeft = 'frontLeft',
  frontRight = 'frontRight',
  rearLeft = 'rearLeft',
  rearRight = 'rearRight',
  windshield = 'windshield',
  left = 'left',
  right = 'right',
  speedometer = 'speedometer',
  damageDetail = 'damageDetail',
  counterpartyRegPlate = 'counterpartyRegPlate',
  counterpartyFrontLeft = 'counterpartyFrontLeft',
  counterpartyFrontRight = 'counterpartyFrontRight',
  counterpartyRearLeft = 'counterpartyRearLeft',
  counterpartyRearRight = 'counterpartyRearRight',
  counterpartyWindshield = 'counterpartyWindshield',
  counterpartyLeft = 'counterpartyLeft',
  counterpartyRight = 'counterpartyRight',
  counterpartyDamageDetail = 'counterpartyDamageDetail',
  property = 'property',
  site = 'site',
  other = 'other',
  otherFallback = 'other,',
}

export function isCounterParty(type: AccidentPhotoType) {
  switch (type) {
    case AccidentPhotoType.counterpartyRegPlate:
      return true;
    case AccidentPhotoType.counterpartyFrontLeft:
      return true;
    case AccidentPhotoType.counterpartyFrontRight:
      return true;
    case AccidentPhotoType.counterpartyRearLeft:
      return true;
    case AccidentPhotoType.counterpartyRearRight:
      return true;
    case AccidentPhotoType.counterpartyWindshield:
      return true;
    case AccidentPhotoType.counterpartyLeft:
      return true;
    case AccidentPhotoType.counterpartyRight:
      return true;
    case AccidentPhotoType.counterpartyDamageDetail:
      return true;
    default:
      return false;
  }
}

export function isDetail(type: AccidentPhotoType) {
  return type.includes('detail') || type.includes('Detail');
}
