export const translations = {
  counterpartyFrontLeft: 'Levá přední strana',
  counterpartyFrontRight: 'Pravá přední strana',
  counterpartyLeft: 'Levá boční strana',
  counterpartyRearLeft: 'Levá zadní strana',
  counterpartyRearRight: 'Pravá zadní strana',
  counterpartyRegPlate: 'Viditelná SPZ',
  counterpartyRight: 'Pravá boční strana',
  counterpartyWindshield: 'Čelní sklo vozidla',
  frontLeft: 'Levá přední strana',
  frontRight: 'Pravá přední strana',
  left: 'Levá boční strana',
  rearLeft: 'Levá zadní strana',
  rearRight: 'Pravá zadní strana',
  regPlate: 'Viditelná SPZ',
  right: 'Pravá boční strana',
  windshield: 'Čelní sklo vozidla',
  policeProtocol: 'Policejní protokol',
  euProtocol: 'Záznam o dopravní nehodě',
  speedometer: 'Tachometr',
  property: 'Poškozený cizí majetek',
  site: 'Místo nehody',
  damageDetail: 'Detail škody',
  counterpartyDamageDetail: 'Detail škody',

  myVehicle: 'Moje vozidlo',
  counterpartyVehicle: 'Vozidlo protistrany',
  other: 'Ostatní',
};
